/* General Styles */
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  background: var(--light-color);
}
html
{
  background-color: black;
}
a {
  text-decoration: none;
}

ul {
  list-style: none;
}

/* Colors */
:root {
  --dark-color-a: #070707;
  --dark-color-b: #060c74;
  --light-color: #000000;
  --success-color: #5cb85c;
  --error-color: #d9534f;
}

#root{
  background-color: #fff;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  background: rgb(237,103,103);
  background: linear-gradient(347deg, rgba(237,103,103,1) 0%, rgba(237,103,103,1) 15%, rgba(119,222,165,1) 100%);
}

/* Overlay Styles */
.customPromptOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.customPrompt {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* Buttons */
.btn {
  cursor: pointer;
  padding: 7px;
  color: var(--dark-color-a);
  border: 0;
  font-size: 14px;
  border-radius: 10px;
}



/* Chat Page */
.chat-container {
  width: 90%;
  height: 610px;
  background: #fff;
  margin: 10px auto;
  overflow: hidden;
  border-radius: 5px;
}

.chatWindow{
  width: 100%;
  height:100%;
  background-color: #000000;
}

.chat-header {
  background: #aa0000;
  color: #fff;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-main {
  display: flex;
  height: 460px;
}

.chat-sidebar {
  padding: 20px;
  height: 460px;
  width: 70%;
  overflow-y: scroll;
}

.chat-sidebar h2 {
  font-size: 20px;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 20px;
}

.chat-sidebar h3 {
  margin-bottom: 15px;
}

.chat-sidebar ul li {
  padding: 10px 0;
}

.chat-messages {
  padding: 30px;
  height: 460px;
  overflow-y: scroll;
  width: 40%;
}

.chat-messages .message {
  padding: 5px;
  margin-bottom: 15px;
  background-color: #dcd9d9;
  border-radius: 5px;
  overflow-wrap: break-word;
}

.chat-messages .message .meta {
  font-size: 17px;
  font-weight: bold;
  color: var(--dark-color-b);
  opacity: 0.7;
  margin-bottom: 7px;
}

.chat-messages .message .meta span {
  color: #534646;
  font-size: 11px;
  margin-left: 8px;
}

.chat-form-container {
  padding: 20px 30px;
  background-color: #aa0000;
  border-radius: 5px;
}

.chat-form-container form {
  display: flex;
}

.chat-form-container input[type="text"] {
  font-size: 16px;
  padding: 5px;
  height: 40px;
  flex: 1;
  border-radius: 10px;
  margin-right: 4px;
}

/* Join Page */
.join-container {
  width: 35%;
  color: #fff;
}

.join-header {
  text-align: center;
  padding: 20px;
  background: #ed6767;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.join-main {
  padding: 30px 40px;
  background: var(--dark-color-b);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #77dea5;

}

.join-main p {
  margin-bottom: 20px;
}

.join-main .form-control {
  margin-bottom: 20px;
}

.join-main label {
  display: block;
  margin-bottom: 5px;
}

.join-main input[type="text"] {
  font-size: 16px;
  padding: 5px;
  height: 40px;
  width: 100%;
}

.join-main select {
  font-size: 16px;
  padding: 5px;
  height: 40px;
  width: 100%;
}

.join-main .btn {
  margin-top: 20px;
  width: 100%;
}

/* Responsive Styles */
@media (max-width: 1150px) {
  .chat-main {
    display: block;
  }

  .chat-messages{
    display: none;
  }

  .chat-sidebar{
    width: 100%;
    justify-content: center;
  }

}

#copycode{
  padding: 7px;
  font-size: 14px;
  border-radius: 10px;
}

#play-btn, #next-btn, #queue-btn,#videoinput{
  padding: 5px;
  font-size: 12px;
  border-radius: 7px;
  margin-left: 5px;
  margin-bottom: 5px;
}

#play-pause-btn,#sync-btn{
  padding: 5px;
  font-size: 12px;
  border-radius: 7px;
  margin-left: 5px;
  margin-top: 5px;
}

/* styles.css or your custom CSS file */
.tooltip-container {
  display: inline-block;
  position: relative;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  z-index: 1; /* Add a higher z-index value to bring the tooltip in front of the button */
}

#room-unavail{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #ff0000;
  font-weight: bold;
/* justify-content: center; */
}

#username,#room{
  border-radius: 10px;

}
